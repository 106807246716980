import styled from 'styled-components';

import { COLORS } from '../../constants.styles';

export const StyledInfoWithImageWrapper = styled.div`
  padding-left: 50px;
  padding-right: 50px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;

  @media (max-width: 768px) {
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export const ImageWrapper = styled.div`
  min-width: 275px;
  max-width: 500px;
  margin: 20px;

  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-left: 0px;
  }
`;

export const StyledImage = styled.img`
  max-width: ${props => props.width};
  width: auto;
  height: auto;
`;

export const TextBlock = styled.div`
  max-width: 500px;

  margin-right: 30px;
  margin-left: 30px;
  @media (max-width: 768px) {
    margin-right: 20px;
    margin-left: 5px;
  }
`;

export const ContactButton = styled.a`
  color: white !important;
  margin-bottom: 20px;
`;

export const Header = styled.div`
  h1 {
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 50px;
  }
`;

export const Paragraph = styled.div`
  color: ${COLORS.DARK_GREY};
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 40px;
`;
