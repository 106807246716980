/* eslint-disable camelcase */
import React from 'react';
import { get } from 'lodash';
import { graphql } from 'gatsby';

import Page from '../layout/Page';
import Section from '../components/common/Section';

import { Column } from '../components/Column';
import { InfoWithImage } from '../components/InfoWithImage';

import { COLORS, COLUMN_ICON_SIZE } from '../constants.styles';

export default ({ data }) => {
  const serviceColumns = get(data, 'prismicHabitatPage.data.services.document.data');

  const habitatInfoWithImageData = get(data, 'prismicHabitatPage.data.info_with_image_relation.document.data');

  return (
    <Page>
      <Section>
        <InfoWithImage
          header={habitatInfoWithImageData.header}
          description={habitatInfoWithImageData.description}
          link_text={habitatInfoWithImageData.link_text}
          link_target={habitatInfoWithImageData.link_target}
          image={habitatInfoWithImageData.image}
        />
      </Section>
      <Section theme={COLORS.LIGHT_GREY}>
        <div className="container">
          <div className="row">
            {get(serviceColumns, 'body', []).map((column, index) => {
              const { icon, header, description, tail_link, link_text } = column.primary;
              return (
                <Column
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  icon={{
                    url: icon.url,
                    width: COLUMN_ICON_SIZE,
                    height: COLUMN_ICON_SIZE,
                  }}
                  header={header.text}
                  descriptionHTML={description.html}
                  link={tail_link}
                  linkText={link_text}
                />
              );
            })}
          </div>
        </div>
      </Section>
    </Page>
  );
};

export const query = graphql`
  query {
    prismicHabitatPage {
      data {
        info_with_image_relation {
          id
          ... on PrismicLinkType {
            document {
              ... on PrismicInfoWithImage {
                data {
                  header {
                    html
                  }
                  description {
                    html
                  }
                  link_text {
                    text
                  }
                  link_target {
                    text
                  }
                  image {
                    dimensions {
                      width
                      height
                    }
                    url
                  }
                }
              }
            }
          }
        }
        services {
          id
          ... on PrismicLinkType {
            document {
              ... on PrismicColumns {
                data {
                  body {
                    __typename
                    ... on PrismicColumnsBodyColumn {
                      primary {
                        icon {
                          url
                          dimensions {
                            width
                            height
                          }
                        }
                        header {
                          text
                        }
                        description {
                          html
                        }
                        tail_link
                        link_text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
