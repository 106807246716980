import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import {
  StyledInfoWithImageWrapper,
  ContactButton,
  Header,
  Paragraph,
  TextBlock,
  ImageWrapper,
  StyledImage,
} from './InfoWithImage.style';

const InfoWithImage = ({ header, description, link_text, link_target, image }) => {
  return (
    <StyledInfoWithImageWrapper>
      <TextBlock className="flex-column">
        <Header dangerouslySetInnerHTML={{ __html: header.html }} />
        <Paragraph dangerouslySetInnerHTML={{ __html: description.html }} />
        <ContactButton href={link_target.text} className="btn btn-success" role="button">
          {link_text.text}
        </ContactButton>
      </TextBlock>
      <ImageWrapper>
        <StyledImage
          src={image.url}
          alt="screenshot-map"
          width={get(image, 'dimensions.width')}
          height={get(image, 'dimensions.height')}
        />
      </ImageWrapper>
    </StyledInfoWithImageWrapper>
  );
};

InfoWithImage.propTypes = {
  header: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }).isRequired,
  link_text: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }).isRequired,
  link_target: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.shape({
    dimensions: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default InfoWithImage;
